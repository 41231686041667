/* eslint-disable @backstage/no-relative-monorepo-imports */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Content, Header, InfoCard, Page } from '@backstage/core-components';

import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';

import {
  DomainCreationWorkflowRequest,
  DomainOptions,
  Request,
  SDLC_STAGES,
  fetchAccountData,
  triggerRequest,
  validateLowerCaseHyphenSeparatedString,
} from '../../internal';

import {
  APPROVER_EMAILS,
  ROUTE53_DOMAINS,
  SUB_DOMAIN_CREATION_FORM_DESCRIPTION,
  SUB_DOMAIN_CREATION_FORM_NAME,
  SUB_DOMAIN_NAME_MAX_LENGTH,
  SUB_DOMAIN_NAME_MIN_LENGTH,
} from '../../../../../packages/app/src/common/constants';

import { setApplicationAccounts } from '../../../../../packages/app/src/store/actions';
import { addNotifications } from '../../../../../packages/app/src/store/notifications';
import { Account, State } from '../../../../../packages/app/src/store/types';
import { AsynchronousAutoComplete, BreadBoardAutoComplete } from '../common';
import { BreadBoardButtonGroup } from '../common/BreadBoardButtonGroup';

export const SubDomainForm = () => {
  const dispatch = useDispatch();

  const email = useSelector((state: State) => state.email);
  const accessToken = useSelector((state: State) => state.accessToken);

  useEffect(() => {
    if (accessToken) {
      fetchAccountData(accessToken).then(response => {
        dispatch(setApplicationAccounts(response.applicationAccounts));
      });
    }
  }, [dispatch, accessToken]);

  const applicationAccounts = useSelector(
    (state: State) => state.applicationAccounts,
  );

  const [subDomainName, setSubDomainName] = useState<string>(''); // Actual value to be sent to the github workflow
  const [_domainName, setDomainName] = useState<string>(''); // Actual value to be sent to the github workflow
  const [domainString, setDomainString] = useState<string>(''); // Just for display purpose
  const [domain, setDomain] = useState<DomainOptions>({
    domain: '',
    prod: '',
    nonProd: '',
  });
  const [subDomainValidation, setSubDomainValidation] =
    useState<boolean>(false);
  const [selectedAccountDetail, setSelectedAccountDetail] = useState<Account>({
    accountEmail: '',
    accountId: '',
    accountName: '',
    accountOwner: '',
    accountType: '',
    accountState: '',
    accountStage: '',
    requestId: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [approver, setApprover] = useState<string>('');
  const [envValue, setEnvValue] = useState<string>(SDLC_STAGES.NON_PROD);

  const handleReset = () => {
    setSubDomainName('');
    setDomainName('');
    setDomainString('');
    setEnvValue(SDLC_STAGES.DEV);
    setDomain({
      domain: '',
      prod: '',
      nonProd: '',
    });
    setApprover('');
    setIsLoading(false);
    setSubDomainValidation(false);
    setSelectedAccountDetail({
      accountEmail: '',
      accountId: '',
      accountName: '',
      accountOwner: '',
      accountType: '',
      accountState: '',
      accountStage: '',
      requestId: '',
    });
  };

  const submitCheck = () => {
    const canSubmit =
      isLoading ||
      !subDomainName ||
      !selectedAccountDetail ||
      !approver ||
      subDomainValidation;
    return canSubmit;
  };

  useEffect(() => {
    if (selectedAccountDetail.accountName.includes(SDLC_STAGES.PROD)) {
      setDomainName(domain.prod);
      setDomainString(`${subDomainName}.${domain.prod}`);
    } else {
      if (selectedAccountDetail.accountName.includes(SDLC_STAGES.DEV)) {
        setEnvValue(SDLC_STAGES.DEV);
      } else if (selectedAccountDetail.accountName.includes(SDLC_STAGES.QA)) {
        setEnvValue(SDLC_STAGES.QA);
      } else if (
        selectedAccountDetail.accountName.includes(SDLC_STAGES.STAGE)
      ) {
        setEnvValue(SDLC_STAGES.STAGE);
      } else if (selectedAccountDetail.accountName.includes(SDLC_STAGES.UAT)) {
        setEnvValue(SDLC_STAGES.UAT);
      } else {
        setEnvValue(SDLC_STAGES.NON_PROD);
      }
      setDomainName(domain.nonProd);
      setDomainString(
        `${subDomainName}-${envValue.toLowerCase()}.${domain.nonProd}`,
      );
    }
  }, [envValue, domain, subDomainName, selectedAccountDetail.accountName]);

  const onSubDomainNameChange = (event: any) => {
    setSubDomainName(event.target.value);
    setSubDomainValidation(
      !validateLowerCaseHyphenSeparatedString(
        event.target.value,
        SUB_DOMAIN_NAME_MIN_LENGTH,
        SUB_DOMAIN_NAME_MAX_LENGTH,
      ),
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    // subDomainName converted to domainString processed to be displayed to the requester and approver
    const requestMessage = `Request: Create a Sub-Domain<br />
    Requester: ${email}<br />
    AWS Account Name: ${selectedAccountDetail.accountName}<br />
    Sub-Domain: ${domainString}<br /> 
    Domain: ${domain.domain}
    `;

    const requestContent: DomainCreationWorkflowRequest = {
      requester: email,
      accountId: selectedAccountDetail.accountId,
      domainString: subDomainName, // subDomainName as entered by user to be sent to github workflow
      domain: domain.domain,
    };

    const request: Request = {
      requestType: SUB_DOMAIN_CREATION_FORM_NAME,
      description: requestMessage,
      approver: approver,
      requester: email,
      content: requestContent,
    };

    const response = await triggerRequest(request, accessToken);
    const requestId = response.data.requestId;

    addNotifications(
      SUB_DOMAIN_CREATION_FORM_NAME,
      `Request sent for approval to approver: '${approver}'.
      You can track the request '${requestId}' in 'Submissions' Tab in Self-Service.`,
      'info',
    );

    setIsLoading(false);

    handleReset();
  };

  return (
    <Page themeId="tool">
      <Header
        title={SUB_DOMAIN_CREATION_FORM_NAME}
        subtitle={SUB_DOMAIN_CREATION_FORM_DESCRIPTION}
      />
      <Content>
        <Grid container xs={12} md={6} spacing={3} direction="column">
          <InfoCard>
            <FormControl variant="standard" fullWidth margin="dense">
              <AsynchronousAutoComplete
                options={applicationAccounts}
                value={selectedAccountDetail}
                getOptionLabel={option => option.accountName}
                groupBy={option => option.accountName[0]}
                label="Account Name"
                id="account-details"
                onChange={(_event: any, newValue: any) => {
                  setSelectedAccountDetail(newValue);
                }}
              />
              <FormHelperText id="account-name-helper-text">
                Select an account for this subdomain to be created on
              </FormHelperText>
              <br />

              <TextField
                label="Subdomain Name"
                placeholder="Subdomain Name"
                variant="outlined"
                onChange={onSubDomainNameChange}
                value={subDomainName}
                error={subDomainValidation}
                helperText={
                  subDomainValidation
                    ? 'Entered value is not a valid sub-domain name.'
                    : ''
                }
                required
              />
              <FormHelperText id="account-name-helper-text">
                Prod subdomains will be created under{' '}
                <u>
                  <i>analogtools.io</i>
                </u>{' '}
                or{' '}
                <u>
                  <i>app.analog.com</i>
                </u>{' '}
                <br />
                Non-Prod subdomains will be created under{' '}
                <u>
                  <i>analogtools-sandbox.io</i>
                </u>{' '}
                or{' '}
                <u>
                  <i>app-stage.analog.com</i>
                </u>{' '}
                <br />
                The subdomain name should comprise of lowercase alphanumeric
                characters separated by hyphens
                <br />
                The subdomain name must be at least {
                  SUB_DOMAIN_NAME_MIN_LENGTH
                }{' '}
                characters long and cannot exceed {SUB_DOMAIN_NAME_MAX_LENGTH}{' '}
                characters
              </FormHelperText>
              <br />

              <AsynchronousAutoComplete
                id="route53-domains-auto-complete"
                options={ROUTE53_DOMAINS}
                getOptionLabel={option => option.domain}
                value={domain}
                onChange={(_event: any, newValue: any) => {
                  setDomain(newValue);
                }}
                label="Domain"
              />
              <FormHelperText id="route53-domains-helper-text">
                Please choose domain to create the entry under
              </FormHelperText>
              <br />

              <TextField
                label="Full Subdomain"
                placeholder="Full Subdomain"
                value={
                  subDomainName &&
                  domain.domain &&
                  selectedAccountDetail.accountName
                    ? domainString
                    : ''
                }
                variant="outlined"
                disabled
              />
              <FormHelperText>
                This is the full subdomain that will be created.
              </FormHelperText>
              <br />

              <BreadBoardAutoComplete
                id="approver"
                options={APPROVER_EMAILS}
                value={approver}
                onChange={(_event: any, newValue: any) => {
                  setApprover(newValue);
                }}
                label="Approver's Email"
              />
              <FormHelperText id="approver-helper-text">
                Please choose an approver who will authorise the creation of the
                sub-domain.
              </FormHelperText>
              <br />
            </FormControl>

            <BreadBoardButtonGroup
              handleSubmit={handleSubmit}
              handleReset={handleReset}
              submitCheck={submitCheck}
              isLoading={isLoading}
            />
          </InfoCard>
        </Grid>
      </Content>
    </Page>
  );
};
